import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

export function adaptiveMovingAverage(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
  fastestEnd: number,
  lowestEnd: number,
): number {
  const ama = this._context.new_var()
  if (isNaN(ama.get(0))) ama.set(0)
  ama.get(100)

  if (series.hist_pos > length) {
    const diff = this._context.new_var(Math.abs(series.get(0) - series.get(1)))
    const direction = Math.abs(series.get(0) - series.get(length))

    const volatility = this.summation(diff, length)
    const er = volatility !== 0 ? direction / volatility : 0
    const fastest = 2 / (fastestEnd + 1)
    const slowest = 2 / (lowestEnd + 1)
    const sc = Math.pow(er * (fastest - slowest) + slowest, 2)
    const amaValue = ama.get(1) + sc * (series.get(0) - ama.get(1))
    ama.set(amaValue)
  } else {
    ama.set(series.get(0))
  }
  return ama.get(0)
}
